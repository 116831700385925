import { useCallback, useState } from 'react';
import './App.css';
import { Chords } from './components/Chords';
import { Title } from './components/Title';

const baseNotes: string[] = ['C♭', 'C', 'C♯', 'D♭', 'D', 'D♯', 'E♭', 'E', 'E♯', 'F♭', 'F', 'F♯', 'G♭', 'G', 'G♯', 'A♭', 'A', 'A♯', 'B♭', 'B', 'B♯'];

function getRandomChanges(amountOfBars: number): string[] {
  return Array.from('x'.repeat(amountOfBars)).map((_, index) => {
    const randomIndex = Math.floor(Math.random() * baseNotes.length);
    return baseNotes[randomIndex];
  });
}

function useRandomChanges(initialAmountOfBars: number) {
  const [amountOfBars, setAmountOfBars] = useState(initialAmountOfBars);
  const [randomChanges, setRandomChanges] = useState(() => getRandomChanges(amountOfBars));

  const shuffle = useCallback(function shuffle() {
    setRandomChanges(getRandomChanges(amountOfBars));
  }, [amountOfBars]);

  return [amountOfBars, randomChanges, shuffle] as const;
}

function App() {
  const [_amountOfBars, randomChanges, shuffle] = useRandomChanges(32);

  return (
    <div className="paper" role="main">
      <Title />
      <Chords randomChanges={randomChanges}/>
      <button className="shuffle-button" onClick={shuffle}>
        <svg viewBox="0 0 429 344" style={{fillRule:"evenodd", clipRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: "1.5" }}>
          <g>
            <path d="M328.944,198.298L421.344,267.598L328.944,336.898L328.944,198.298Z"/>
            <path d="M261.892,252.065C289.729,266.302 315.865,267.598 315.865,267.598L347.424,267.598" style={{ fill: "none", stroke: "black", strokeWidth: "44px" }}/>
            <path d="M32.228,74.55L72.606,74.55C72.606,74.55 103.607,73.436 135.185,87.033" style={{ fill: "none", stroke: "black", strokeWidth: "44px" }}/>
            <path d="M328.944,5.25L421.344,74.55L328.944,143.85L328.944,5.25Z"/>
            <path d="M32.228,261.379L72.606,261.379C72.606,261.379 184.367,262.397 198.929,166.66C213.491,70.922 315.865,74.55 315.865,74.55L347.424,74.55" style={{ fill: "none", stroke: "black", strokeWidth: "44px" }}/>
          </g>
        </svg>
      </button>
    </div>
 );
}

export default App;
