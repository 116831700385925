import { PropsWithoutRef } from "react";
import { Staff } from './Staff';

export function Chords({randomChanges} : PropsWithoutRef<{randomChanges: string[]}>) {
	return (
		<ul className="sheet">
			{randomChanges.map((randomChord, index) => (
				<li  key={index}>
					<span className="chord-name">{randomChord}</span>
					<Staff />
				</li>
			))}
		</ul>
	);
}

