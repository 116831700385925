export function Staff() {
	return (
		<div className="staff">
			<span></span>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>
	);
}
